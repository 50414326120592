/**
 * Loader
 * @class Loader
 * @returns
 */
var Loader = Klass.create();

//definition start
Loader.extend(JqSprite);
Loader.extend({
/**
 * 初期処理
 */
init:function($o){
	this.o = $o;
	this.anim = false;
	this.list_y = 100;
	this.interval = 5000;
	this.anim_time = _cmn.anim_time;
	this.anim_ease_in = "easeInSine";
	this.anim_ease_out = "easeOutSine";
	this.anim_ease_bound = "easeOutBack";
	this.anim_ease_els = "easeOutElastic";
	this.timer = null;
},
/**
 * 設定
 */
set:function(){
	this.setObject.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
show:function(iTime){
	var oSelf = this;
	oSelf.o.removeClass("hide");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){oSelf.o.removeClass("hide");},[],"");
	oCom.call(this,function(){oSelf.o.animate({opacity:1},iTime,"linear");},[],"");
	oCom.sleep(iTime);
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
hide:function(iTime){
	var oSelf = this;
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this,function(){oSelf.o.animate({opacity:0},iTime,"linear");},[],"");
	oCom.sleep(iTime);
	oCom.call(this,function(){oSelf.o.addClass("hide");},[],"");
	if(oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
destract:function(){
	this.o.remove();
},
/**
 * オブジェクトセット
 */
setObject:function(){
	this.obj = this.o.find("span");
},
/**
 * パラメータセット
 */
setParam:function(){
},
/**
 * 初期位置設定
 */
setState:function(){
},
/**
 * イベントセット
 */
setEvent:function(){
	
},
/**
 * リサイズ
 */
resize:function(){
}
});
