/**
 * Goto
 * @class Goto
 * @returns
 */
var Goto = Klass.create();

//definition start
Goto.extend({
/**
 * 初期処理
 */
init:function($o){
	this.o = $o;
	this.anim = false;
	this.interval = 5000;
	this.anim_time = 600;
	this.anim_ease_in = "easeInSine";
	this.anim_ease_out = "easeOutSine";
	this.anim_ease_bound = "easeOutBack";
	this.anim_ease_els = "easeOutElastic";
	this.timer = null;
	this.is_loop = false;
	this.is_open = false;
	this.scroll = null;
	this.current = "white";
},
/**
 * 設定
 */
set:function(){
	this.setObject.apply(this,[]);
	this.setState.apply(this,[]);
	this.setEvent.apply(this,[]);
},
/**
 * オブジェクトセット
 */
setObject:function(){
	this.link = this.o.find("a");
},
/**
 * パラメータセット
 */
setParam:function(){
},
/**
 * 初期位置設定
 */
setState:function(){
},
/**
 * イベントセット
 */
setEvent:function(){
	this.link.on("click",function(){
		console.log("aaaa");
		_cmn.gotoTop("#top",0);
		return false;
	});
	this.setWayPoint();
},
/**
 * setWayPoint
 */
setWayPoint:function(){
},
/**
 * change
 */
change:function(b){
//	console.log($(window).scrollTop(),$("#fv").height());
	if(b){
		_cmn.go.link.addClass("show");
		return;
	}
	if(b === false){
		_cmn.go.link.removeClass("show");
		return;
	}
	
	if($(window).scrollTop()+200 > $("#fv").height()){
		_cmn.go.link.addClass("show");
	}else{
		_cmn.go.link.removeClass("show");
	}
},
/**
 * リサイズ
 */
resize:function(){
}
});
